
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Casper-primary: mat.define-palette(mat.$orange-palette);
$Casper-accent: mat.define-palette(mat.$brown-palette);

// The warn palette is optional (defaults to red).
$Casper-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Casper-light-theme: mat.define-light-theme((
  color: (
    primary: $Casper-primary,
    accent: $Casper-accent,
    warn: $Casper-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Casper-light-theme);

$Casper-dark-theme: mat.define-dark-theme((
  color: (
    primary: $Casper-primary,
    accent: $Casper-accent,
    warn: $Casper-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

.dark-theme {
  background-color: #2f2f2f;
  color: #fafafa;
  @include mat.all-component-themes($Casper-dark-theme);
}


$body-bg: #2f2f2f;
$body-color: #fafafa;

@import 'bootstrap/scss/bootstrap.scss';

html, body, app-root { height: 100%; width: 100%; display: block; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-mdc-chip-list-wrapper {
  display: inline !important;
  margin: 0px !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: #8d8d8d !important;
  background-image: linear-gradient(-45deg, #808080, #8d8d8d 75%, #8d8d8d 26%, #808080) !important;
  box-shadow: 0 3px 1px -2px rgba(63, 63, 63, 0.2), 0 2px 2px 0 rgba(76, 76, 76, 0.14), 0 1px 5px 0 rgba(93, 93, 93, 0.12);
}

.mat-mdc-chip > fa-icon, .mat-mdc-chip > i, .mat-mdc-chip-text  {
  height: 20px;
  min-width: 18px;
  text-align: center;
  text-shadow: 2px 2px 3px rgba(23, 23, 23, 0.2);
}

.mat-mdc-chip > fa-icon {
  margin-bottom: -2px !important;
}

.mat-mdc-chip-text {
  margin-bottom: -3px !important;
}

.mat-mdc-chip > i {
  padding-top: 3px !important;
}

.mat-mdc-card-title {
  text-shadow: 2px 2px 3px rgba(23, 23, 23, 0.2);
  padding: 16px 16px 0 16px;
}

.mdc-card__actions {
  padding: 16px !important;
}

.mat-mdc-button .mat-mdc-button-touch-target, .mat-mdc-unelevated-button .mat-mdc-button-touch-target, .mat-mdc-raised-button .mat-mdc-button-touch-target, .mat-mdc-outlined-button .mat-mdc-button-touch-target {
  height: 100% !important;
}

.mat-mdc-button.mat-mdc-button-base, .mat-mdc-raised-button.mat-mdc-button-base, .mat-mdc-unelevated-button.mat-mdc-button-base, .mat-mdc-outlined-button.mat-mdc-button-base {
  min-height: 36px;
  height: fit-content !important;
}
/*
.mat-toolbar.mat-primary {
  background-image: linear-gradient(-45deg, #e76600, #ff9800 75%, #ff9800 26%, #e76600) !important;
}
*/
/*
.mat-raised-button.bg-primary {
  background-image: linear-gradient(-45deg,#0062cb,#007bff 75%,#007bff 26%,#0062cb) !important;
}
*/

.h1, h1 {
  font-size: 1.5rem !important;
}
